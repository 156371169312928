:root {
    --colorBackground: 49, 71, 118;
    --colorHeader: 14, 39, 95;
    --colorText: 255, 255, 255;
    --colorLink: 139, 185, 87;
}

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
.material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

html, body {margin: 0; height: 100%; }
body {
    padding-top: 64px;
    background: rgb(var(--colorBackground));
    color: rgb(var(--colorText));
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 400;
}
article {margin: 0 auto; max-width: 960px; }

header {
    background: rgb(var(--colorHeader));
    position: fixed;
    top: 0; left: 0; right: 0;
}
header > div {
    margin: 0 auto;
    max-width: 960px;
}
header div > p {
    margin: 0;
    /* height: 64px; */
    line-height: 64px;
    font-size: 18px;
    display: inline-block;
    /* width: 50%; */
    vertical-align: top;
}
header div > p a {
    padding: 0 0 0 140px;
    display: inline-block;
    text-decoration: none;
    background: transparent url(./assets/ford.png) no-repeat;
    background-position: center left 12px;
}
header div > p:last-child {
    float: right;
    text-align: right;
}
header div > p:last-child a {
    padding: 0 8px 0 0px;
    background: transparent none;
}

main h1 {
    margin: 8px 0; padding: 0 0 0 12px;
    font-size: 24px;
    font-weight: normal;
    color: rgb(var(--colorLink));
    line-height: 48px;
}
main h2 {
    font-size: 24px;
    font-weight: 400;
}
main a {
    color: rgb(var(--colorText));
}
main a:hover {
    color: rgb(var(--colorLink));
}

section {
    margin: 0 8px 8px; padding: 4px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

footer {
    margin: 12px 0; padding: 12px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
footer nav {
    margin: 0 auto; padding: 0 12px;
    max-width: 960px;
    font-size: 12px;
}
nav a {
    padding: 0 1em;
    display: inline-block;
    color: rgb(var(--colorText));
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    text-decoration: none;
    text-transform: uppercase;
    height: 24px; line-height: 24px;
    vertical-align: bottom;
}
nav .mat-icon {
    vertical-align: top;
}
nav a:first-child {padding-left: 0; border-left: 0 none; }

button {
    padding: 8px;
    background-color: rgb(var(--colorLink));
    color: white;
    border-radius: 12px;
    border: 0 none;
    font-size: 16px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4)
}
button:active {
    position: relative;
    left: 1px; top: 1px;
    box-shadow: 0 0 0;
}

table.data {
    margin: 12px 0;
    border-collapse: collapse;
}
table.data thead td {
    font-weight: bold;
}
table.data td {
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
table.data tbody tr:nth-child(odd) td, table.data tbody tr:nth-child(odd) th {
    background-color: rgba(255, 255, 255, 0.2);
}
table.data td.number {
    text-align: right;
}

#login label {
    display: inline-block;
    width: 6em;
}
p.warning {color: red; }

header nav {
    padding: 12px;
    display: none;
    position: absolute;
    top: 64px; right: 50%;
    margin-right: -480px;
    background: #0e275f;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
}
header nav.active {
    display: block;
}
header nav ul {
    margin: 0; padding: 0;
    list-style-type: none;
}
header nav ul li {
    margin: 4px; padding: 4px;
    /* list-style-type: none; */
}

@media (max-width: 960px) {
    header nav {
        right: 0;
        margin-right: 0;
    }
}

/* Mobile */
@media (max-width: 540px) {
    body {
        padding-top: 32px;
    }
    
    header div > p {
        line-height: 32px;
        font-size: 14px;
    }
    header div > p a {
        padding: 0 0 0 80px;
        background-size: 58px 22px;
    }
    footer {
        margin: 0;
        position: fixed;
        bottom: 0; left: 0; right: 0;
        text-align: center;
        vertical-align: middle;
        background: rgba(var(--colorBackground), 0.8);
    }
}
